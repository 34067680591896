@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Beatrice+Deck+Trial:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Beatrice+Trial:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Beatrice+Display+Trial:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Glegoo|Roboto:300,400");

@font-face {
  font-family: 'GrifterBold';
  src: url('./assets/fonts/grifterbold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}


body {
  @apply leading-[normal] m-0;
  font-family: 'Poppins' ;
}
*,
::before,
::after {
  border-width: 0;
}


@layer components{

   .btn-primary {
     @apply bg-indigo-500 hover:bg-indigo-600 text-white font-bold py-2 px-4 rounded;
   }
   
}
.fade-image {
  mask-image: linear-gradient(
    to left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.9) 50%
  );
  -webkit-mask-image: linear-gradient(
    to left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.9) 50%
  );
}
.fade-image-bottom {
  mask-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.9) 50%
  );
  -webkit-mask-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.9) 50%
  );
}
.fade-image-radial {
  -webkit-mask-image: radial-gradient(ellipse 90% 80% at 48% 50%, black 40%, transparent 50%);
  mask-image: radial-gradient(ellipse 90% 80% at 48% 50%, black 40%, transparent 50%);
}

.modal-enter {
  transform: scale(0.9);
  opacity: 0;
}

.modal-enter-active {
  transform: scale(1);
  opacity: 1;
  transition: opacity 300ms, transform 300ms;
}

.modal-exit {
  transform: scale(1);
  opacity: 1;
}

.modal-exit-active {
  transform: scale(0.9);
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
}


.cardxx {
  @apply  bg-white rounded-lg flex justify-center items-center shadow-md m-1;
  /* Add any additional custom styling here */
}

.slick-slider .slick-slide {
  padding: 0 0px;
}
.slick-center {
  transform: scale(1.6);
  z-index: 10;
}

/* Typewriter effect 1 */
@keyframes typing {
  0.0000%, 27.3488% { content: ""; }
  1.1395%, 26.2093% { content: "d"; }
  2.2791%, 25.0698% { content: "de"; }
  3.4186%, 23.9302% { content: "dev"; }
  4.5581%, 22.7907% { content: "deve"; }
  5.6977%, 21.6512% { content: "devel"; }
  6.8372%, 20.5116% { content: "develo"; }
  7.9767%, 19.3721% { content: "develop"; }
  9.1163%, 18.2326% { content: "develope"; }
  10.2558%, 17.0930% { content: "developer"; }

  30.7674%, 51.2791% { content: ""; }
  31.9070%, 50.1395% { content: "w"; }
  33.0465%, 49.0000% { content: "wr"; }
  34.1860%, 47.8605% { content: "wri"; }
  35.3256%, 46.7209% { content: "writ"; }
  36.4651%, 45.5814% { content: "write"; }
  37.6047%, 44.4419% { content: "writer"; }

  54.6977%, 75.2093% { content: ""; }
  55.8372%, 74.0698% { content: "r"; }
  56.9767%, 72.9302% { content: "re"; }
  58.1163%, 71.7907% { content: "rea"; }
  59.2558%, 70.6512% { content: "read"; }
  60.3953%, 69.5116% { content: "reade"; }
  61.5349%, 68.3721% { content: "reader"; }

  78.6279%, 96.8605% { content: ""; }
  79.7674%, 95.7209% { content: "h"; }
  80.9070%, 94.5814% { content: "hu"; }
  82.0465%, 93.4419% { content: "hum"; }
  83.1860%, 92.3023% { content: "huma"; }
  84.3256%, 91.1628% { content: "human"; }
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

.typewriter {
  --caret: currentcolor;
}

.typewriter::before {
  content: "";
  animation: typing 13.5s infinite;
}

.typewriter::after {
  content: "";
  border-right: 1px solid var(--caret);
  animation: blink 0.5s linear infinite;
}

.typewriter.thick::after {
  border-right: 1ch solid var(--caret);
}

.typewriter.nocaret::after {
  border-right: 0;
}


@media (prefers-reduced-motion) {
  .typewriter::after {
    animation: none;
  }
  
  @keyframes sequencePopup {
    0%, 100% { content: "developer"; }
    25% { content: "writer"; }
    50% { content: "reader"; }
    75% { content: "human"; }
  }

  .typewriter::before {
    content: "developer";
    animation: sequencePopup 12s linear infinite;
  }
}