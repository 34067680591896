/* Container for blog content */
.blog_detail {
    line-height: 1.6;
    color: #eee;
    margin: 0 auto;
  }
  
  .blog_detail br {
    line-height: 100em;
  }
  /* Headings */
  .blog_detail h1,
  .blog_detail h2,
  .blog_detail h3,
  .blog_detail h4,
  .blog_detail h5,
  .blog_detail h6 {
    margin: 1.5em 0 0.5em;
  }
  
  
  .blog_detail h1 {
    font-size: 2.5em;
  }
  
  .blog_detail h2 {
    font-size: 2em;
  }
  
  .blog_detail h3 {
    font-size: 1.75em;
  }
  
  .blog_detail h4 {
    font-size: 1.5em;
  }
  
  .blog_detail h5 {
    font-size: 1.25em;
  }
  
  .blog_detail h6 {
    font-size: 1em;
  }
  
  /* Paragraphs */
  .blog_detail p {
    font-family: Poppins!important;
    margin: 1em 0;
  }
  
  /* Links */
  .blog_detail a {
    color: #3498db;
    text-decoration: none;
  }
  
  .blog_detail a:hover {
    text-decoration: underline;
  }
  
  /* Lists */
  .blog_detail ul,
  .blog_detail ol {
    margin: 1em 0;
    padding-left: 40px;
  }
  
  .blog_detail ul li,
  .blog_detail ol li {
    margin-bottom: 0.5em;
  }
  
  /* Blockquotes */
  .blog_detail blockquote {
    border-left: 4px solid #ccc;
    padding: 0.5em 1em;
    margin: 1em 0;
    color: #666;
    background-color: #f1f1f1;
  }
  
  .blog_detail blockquote p {
    margin: 0;
  }
  
  /* Images */
  .blog_detail img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 1em 0;
  }
  
  /* Tables */
  .blog_detail table {
    width: 100%;
    border-collapse: collapse;
    margin: 1em 0;
  }
  
  .blog_detail table th,
  .blog_detail table td {
    border: 1px solid #ddd;
    padding: 0.5em;
    text-align: left;
  }
  
  .blog_detail table th {
    background-color: #f1f1f1;
  }
  
  /* Code */
  .blog_detail code {
    background-color: #f1f1f1;
    padding: 0.2em 0.4em;
    border-radius: 4px;
    font-family: 'Courier New', Courier, monospace;
  }
  
  .blog_detail pre {
    background-color: #f1f1f1;
    padding: 1em;
    border-radius: 4px;
    overflow-x: auto;
  }
  
  /* Horizontal Rule */
  .blog_detail hr {
    border: 0;
    border-top: 1px solid #ccc;
    margin: 2em 0;
  }
  