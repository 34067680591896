.accordion-title {
    font-family: "GrifterBold", sans-serif;
    flex-grow: 1;
    flex-basis: auto;
    cursor: pointer;
    background-color: transparent;
    width: 100%;
    gap: 20px;
    text-align: left;
    padding: 10px;
    color: #fff;
    font-weight: 700;
    font-size: 20px;
    border-bottom: 0.01em solid #222;
    @media (max-width: 991px) {
      max-width: 100%;
      margin-top: 40px;
    }
  }
  
  .accordion-icon {
    float: right;
  }
  
  .accordion-content {
    padding: 10px;
    display: none;
    color: #aaa;
    font-family: 'Poppins';
  }
  
  /* To display the content */
  .accordion-content {
    display: block;
  }
  
  /* Style adjustments for open state */
  .accordion-icon.open {
    transform: rotate(45deg); /* Or any rotation that suits your '+' to 'x' transition */
  }
  .accordion {
    background-color: transparent;
  }